.contact-main {

	#internal_content_wrapper {
		flex-direction: row;
	}

	#internal_header {
		max-width: 1024px;
		margin: 0 auto;
	}
}

.contact-wrapper {

	.contact-text-container {
		width: 48%;
		padding: 40px 20px 40px 20px;

		.contact-text {
			padding: 17px 10px 17px 0;

			h2 {
				font-size: 2.5rem;
				color: $primary;
				font-weight: 700;
			}

			h3 {
				font-size: 1.7rem;
				color: $primary;
				font-weight: 700;
			}

			p {
				font-size: 1.4rem;
				color: $primary;
			}

			h4 {
				font-size: 1.7rem;
				color: $primary;
				font-weight: 500;

				a {
					color: #1b5733;
					text-decoration: none;
				}
			}
		}

		#question-title {
			font-size: 1.3rem;
			font-weight: 700;
			color: $primary;
			padding: 20px 0 10px;
		}

		p {
			font-size: 1.1rem;
			color: $primary;
			line-height: 1.2;
		}

		#contact_form {
			display: flex;
			flex-direction: column;
			font-weight: 700;
			padding-top: 20px;
			position: relative;

			.error {
				border: 1px solid #b10101;
			}

			.response_message {

				.error {
					border: none;
					color: red;
				}

				.success {
					padding: 10px;
					border-radius: 5px;
					color: green;
				}
			}

			label {
				padding: 5px;
				font-size: 1.1rem;
			}

			input {
				border: thin solid $accent;
				padding: 15px;
				align-items: center;
				margin-bottom: 20px;
				background-color: $accent;
				font-family: $font;
				font-size: 1.2rem;
			}

			.select-wrapper {
				position: relative;

				select {
					padding: 15px;
					height: 54px;
					align-items: center;
					margin-bottom: 20px;
					text-indent: 1px;
					text-overflow: '';
					position: relative;
					cursor: pointer;
					width: 100%;
					background-color: $accent;
					color: $primary;
					border: thin solid $accent;
					font-family: $font;
					font-size: 1.2rem;

					&.error {
						border: 1px solid #b10101;
					}
				}
			}

			textarea {
				border: thin solid $accent;
				padding: 15px;
				align-items: center;
				margin-bottom: 20px;
				background-color: $accent;
				font-family: $font;
				font-size: 1.2rem;
			}

			button {
				width: 100%;
				box-shadow: 0px 2px 8px rgba(0, 0, 0, .47);
			}
		}
	}

	#hp_wrap {
		position: fixed;
		top: -9999px;
		left: -9999px;
	}
}

#locations_map {

	&.contact-map {
		width: 48%;
		height: 970px;
		background-color: $accent;
		margin: 45px 20px 45px 20px;
	}
}



/***>>>Media Queries***/
@media screen and (max-width: 950px) {

	.contact-wrapper {

		.contact-text-container {
			padding: 0;

			.contact-text {

				p {
					font-size: 1.3rem;
				}

				h3 {
					font-size: 1.5rem;
				}

				padding: 17px 0;
			}
		}
	}

	#locations_map {

		&.contact-map {

			height: 920px;
		}
	}
}

@media screen and (max-width: 850px) {

	.contact-main {

		#internal_content_wrapper {
			flex-direction: column;
		}
	}

	#locations_map {

		&.contact-map {
			display: none;
		}
	}

	.contact-wrapper {

		.contact-text-container {
			padding: 0;

			#question-title {
				width: 400px;
			}

			p {
				width: 400px;
			}

			.contact-text {

				padding: 17px 0;
				width: 400px;

				p {
					font-size: 1.3rem;
				}

				h3 {
					font-size: 1.5rem;
				}
			}

			#contact_form {

				input {
					width: 190%;
				}

				.select-wrapper {
					width: 200%;

					select {
						width: 100%;
					}
				}

				textarea {
					width: 190%;
				}

				button {
					width: 200%;
					margin-bottom: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {

	.contact-wrapper {

		.contact-text-container {
			padding: 0;

			#question-title {
				width: 300px;
			}

			p {
				width: 300px;
			}

			.contact-text {
				padding: 17px 0;


				p {
					font-size: 1.3rem;
				}

				h2 {
					font-size: 2rem;
				}

				h3 {
					font-size: 1.5rem;
				}
			}

			#contact_form {

				.select-wrapper {

					select {
						width: 103.8%;
					}
				}
			}
		}
	}
}
