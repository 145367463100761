#faq_main {
	/* stylelint-disable selector-max-id */

	#internal_header {
		margin: 0;

		.tips_faq_title {
			background: $primary;
			border-top-right-radius: 15% 80%;
			font-size: 57px;
			text-align: center;
			font-weight: 700;
			color: #fff;
			display: inline-block;
			width: 36%;
			opacity: .5;
			cursor: pointer;

			&.active {
				opacity: 1;
			}

			&:last-child {
				border-top-right-radius: 15% 75%;
				border-top-left-radius: 15% 75%;
				margin-left: 30px;
				width: 45%;
				display: inline-block;
			}

			.tips_pls {
				display: none;
				position: absolute;
				transition: .2s;
				font-size: 46px;
				margin-top: -10px;
				color: #fff;
				cursor: pointer;
				margin-left: 0;
			}
		}
	}
}

#faq_container {
	background: #fff;
	padding: 30px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	z-index: 99;

	.faq_item {

		.faq_question {
			padding: 10px 0 20px 30px;
			cursor: pointer;

			&:hover {
				background: hsla(0, 0%, 0%, .05);
			}

			.faq_arrow {
				position: absolute;
				display: inline-block;
				width: 0;
				height: 0;
				border-top: 10px solid transparent;
				border-left: 10px solid #bf9459;
				border-bottom: 10px solid transparent;
				-webkit-transition: .2s;
				transition: .2s;

				&.open {
					transform: rotate(90deg);
				}
			}

			p {
				font-size: 21px;
				font-weight: 700;
				display: inline-block;
				margin-left: 30px;
				color: $primary;
			}
		}

		.faq_answer {
			background: #f1f2f2;
			transition: all .2s linear;
			padding: 0;
			opacity: 0;
			height: 0;

			p {
				font-size: 19px;
				font-weight: 400;
				color: $primary;
				display: none;
				padding: 0 30px;
			}

			&.open {
				transition: all .2s linear;
				opacity: 1;
				height: 100%;
				padding: 30px 0;

				p {
					display: block;
				}
			}

			.monthly, .daily {
				font-weight: 700;
			}

			.daily {
				margin-top: 1em;
			}
		}
	}
}

#tips_container {

	h2 {
		font-size: 32px;
		font-weight: 700;
		margin: 10px 0 10px 30px;
		color: $primary;
	}

	.tips_content {
		line-height: 22px;
		color: $primary;
		padding: 20px 30px;
		background-image: linear-gradient(180deg, #bf945959, #f1f2f2 5%);

		p {
			font-size: 18px;
			display: block;
			margin: 10px 0;
		}

		h3 {
			font-size: 26px;
			font-weight: 700;
			margin-top: 20px;
		}

		ul {
			list-style-position: inside;

			li {
				font-size: 18px;
				padding: 4px 0;
				list-style-type: disc;
			}
		}
	}
}

@media screen and (max-width: 850px) {

	#faq_main {

		#internal_header {

			.tips_faq_title {
				font-size: 48px;
			}
		}
	}
}
@media screen and (max-width:750px) {

	#faq_main {

		#internal_header {

			.tips_faq_title {
				font-size: 28px;
				padding: 6px 0px 6px 10px;
				display: block;
				cursor: pointer;
				width: 100%;
				border-radius: 0;
				text-align: left;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;

				.tips_pls {
					display: block;
					margin-left: 0;

					&.active {
						transform: rotate(45deg);
						transition: .2s;
					}
				}

				&:last-child {
					border-radius: 0;
					margin-left: 0;
					width: 100%;
					display: block;
				}

				span {
					margin-left: 35px;
				}
			}
		}
	}
}

@media screen and (max-width:500px) {

	#faq_container {
		padding: 5px;

		.faq_item {

			.faq_question {
				padding: 10px 0 20px 4px;
			}
		}
	}
}