/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Rokkitt';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/rokkitt/v27/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd6DMGb8.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: 'Rokkitt';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/rokkitt/v27/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oVcKDMGb8.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: 'Rokkitt';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/rokkitt/v27/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pycKDMGb8.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Russo One';
	font-style: normal;
	font-weight: 400;
	src: local('Russo One'), local('RussoOne-Regular'), url(https://fonts.gstatic.com/s/russoone/v5/RO6e96EC9m6OLO0tr7J3z6CWcynf_cDxXwCLxiixG1c.ttf) format('truetype');
}

$font: 'Rokkitt', serif;
$secondary_font: 'Russo One', serif;

/*********************
* Colors
*********************/
$primary: #3e693e;
$primary_light: #37b34a;
$secondary: #ef4136;
$accent: #ecddc8;

/*********************
* Reusable Styles
*********************/



/*** ID ***/

#skip_nav {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

#container {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	min-height: 100%;
}

main {
	flex-grow: 1;
}

/*** Classes ***/

.btn {
	position: relative;
	display: inline-block;
	text-decoration: none;
	color: #fff;
	font-family: $font;
	font-size: 16px;
	padding: 10px 30px 10px 10px;
	cursor: pointer;
	background: $secondary;
	border: 2px solid #fff;
	text-align: center;

	&:hover {
		filter: saturate(1.3);
	}

	&::before {
		content: '';
		position: absolute;
		top: 5%;
		left: 2%;
		right: 2%;
		width: auto;
		height: 5%;
		background-color: #f36d54;
	}

	&::after {
		content: '';
		position: absolute;
		right: 3%;
		top: 50%;
		display: block;
		border-right: 3px solid #fff;
		border-bottom: 3px solid #fff;
		width: 10px;
		height: 10px;
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}