#internal_content_wrapper {
	flex-direction: column;
}

#feat_main {
	/* stylelint-disable selector-max-id */

	#internal_header {

		.internal_title {

			h1 {
				font-size: 3.5rem;
			}
		}
	}

	#internal_content {
		background-image: linear-gradient(180deg, #ecddc8, #ffffff 27px);
		padding: 36px 0px;

		h2 {
			font-size: 1.5rem;
			font-weight: 700;
			color: $primary;
			margin-bottom: 20px;
			padding-left: 20px;
		}

		.feat_item {

			.feat_select {
				display: flex;
				align-items: center;
				padding-left: 12px;
				margin-bottom: 20px;
				cursor: pointer;

				img {
					height: 40px;
					width: 40px;
					padding: 0px 25px;

					&.conv_loc {
						width: 30px;
					}
				}

				.feat_plus {
					font-size: 3.2rem;
					color: $primary;
					font-family: sans-serif;
					cursor: pointer;
					transform: rotate(45deg);
					transition: .2s;

					&.open {
						transform: rotate(90deg);
					}
				}

				span {
					font-size: 1.2rem;
					color: $primary;
				}
			}

			.feat_dropdown {
				display: none;
				background: $accent;
				background: linear-gradient(180deg, $accent 0%, rgba(255, 255, 255, 1) 9%, rgba(255, 255, 255, 1) 92%, $accent 100%);
				transition: all .2s linear;
				padding: 0;
				opacity: 0;
				height: 0;

				&.open {
					display: flex;
					transition: all .2s linear;
					opacity: 1;
					height: 100%;
					padding: 30px 0;
				}

				p {
					color: $primary;
					font-size: 1.2rem;
					padding: 0 20px;
				}

				.locs {
					display: flex;
					padding: 5px 5px 10px 5px;
					width: 50%;

					.loc-right-container {
						display: flex;
						flex-direction: column;
						width: 100%;

						/* stylelint-disable-next-line */
						* {
							padding-left: 10px;
						}

						strong {
							font-size: 1.3rem;
							color: $primary;
							font-weight: 700;
							padding-left: 20px;
							margin-bottom: 10px;
						}

						.loc-links-wrapper {
							display: none;
							// display: grid;
							// align-items: center;
							// grid-template-columns: 51% 5% 7% 14%;
							// grid-column-gap: 1em;
							// margin-bottom: 20px;

							// h3 {
							// 	font-size: 1.3rem;
							// 	color: $primary;
							// 	padding-left: 20px;
							// }

							// a {
							// 	padding-right: 30px;
							// }
						}

						.location_item {
							display: flex;

							.loc-name {

								h2 {
									font-size: 2.2rem;
									color: $primary;
									font-weight: 700;
									margin-bottom: 10px;
									padding-left: 0;
								}
							}

							h1 {
								font-size: 3.6rem;
								color: $primary;
								font-weight: 700;
							}

							p {
								font-size: 1.3rem;
								color: $primary;
							}

							a {
								text-decoration: none;
								font-size: 1.2rem;
								color: $primary;
								padding-bottom: 5px;

								&:hover {
									text-decoration: underline;
								}
							}

							.rates-wrapper {
								display: flex;
								align-items: center;
							}
						}

						.rates-divider {
							margin: 6px 0px 7px 34px;
							width: 93%;
							border: solid #f6f2e9 1px;
						}
					}
				}
			}

			.last_item {
				display: flex;
				flex-direction: column;
				font-size: .8rem;
				color: $primary;
			}
		}
	}

	#locations_map_wrapper {
		width: 55%;
		min-height: 200px;
	}

	#locations_map {
		width: 100%;
		height: 100%;
		margin: 0;
		border: thin solid lightgray;
		border-radius: 15px;
	}

	/*---------Media Queries------------------*/

	@media screen and (max-width: 1100px) {

		#internal_content {

			.feat_item {

				.feat_dropdown {

					.locs {

						.loc-right-container {

							.location_item {
								grid-template-columns: 15% 73% 17%;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 850px) {

		#locations_map_wrapper {
			width: 90%;
			height: 245px;
			margin: 0 auto;
			padding-bottom: 10px;
		}

		#internal_content {

			.feat_item {

				.feat_dropdown {
					flex-direction: column;

					.locs {
						width: 90%;

						.loc-right-container {

							hr:not(:last-child) {
								width: 201%;
							}

							.loc-links-wrapper {
								grid-template-columns: 121% 7% 15% 14%;
							}

							.location_item {
								grid-template-columns: 29% 158% 17%;
							}
						}
					}
				}
			}

			.loc-map {
				margin-top: 0px;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 500px) {

		#internal_content {

			.feat_item {

				.feat_dropdown {

					&.open {
						padding: 11px 12px;
					}

					.locs {

						.loc-right-container {

							hr:not(:last-child) {
								width: 195%;
							}

							.loc-info {
								grid-template-columns: 29% 147% 17%;

								.loc-name {

									h2 {
										font-size: 1.8rem;
									}
								}

								h1 {
									font-size: 2.8rem;
								}

								p {
									font-size: 1.1rem;
								}

								img {
									height: 35px;
								}
							}
						}
					}
				}
			}

			.loc-map {
				width: 100%;
			}
		}
	}
}