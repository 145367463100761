#rates_top {
	max-width: 1920px;
	width: 100%;
	margin: 20px auto 0;
	position: relative;
}

#rates_top_overlay {
	background-color: $primary;
	border-radius: 20px;
	max-width: 1440px;
	min-height: 470px;
	width: 100%;
	margin: 0 auto;
	z-index: 1;
}

#rates_top_bg {

	background-image: url(/dist/images/bg/rates_bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 330px;
	width: 100%;
	border-radius: 10px;
	z-index: 2;
	position: absolute;
	top: 0;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(62, 105, 62, .9);
		border-radius: 10px;
	}
}

#rates_top_info {
	max-width: 1440px;
	min-height: 450px;
	margin: 0 auto;
	z-index: 3;
	padding: 20px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin-left: auto;
	margin-right: auto;
}

#facility_caro {
	width: 50%;
	margin-right: 22px;
}

#facility_info {
	display: flex;
	flex-direction: column;
	margin-left: 22px;
	width: 50%;
	color: #fff;

	a {
		text-decoration: none;
		color: #fff;
		font-size: 1.4rem;
	}

	img {
		margin-right: 15px;
	}

	.title {
		display: flex;
		align-items: center;

		hgroup {
			margin-bottom: 20px;
		}

		h2 {
			font-size: 2.7rem;
			font-weight: 600;
		}

		h3 {
			font-size: 1.4rem;
			font-weight: 400;
		}
	}

	.phone {
		margin-bottom: 20px;
	}
}

#hr_social_container {
	padding: 0 30px;

	.hours {
		display: flex;
		line-height: 1.2;

		.office_hours, .access_hours {
			font-size: 1.1rem;

			h3 {
				font-size: 1.5rem;
				font-weight: 400;
			}
		}

		.office_hours {
			margin-right: 70px;
		}
	}

	.socials {
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: #fff;
		color: $primary;
		font-size: 1.6rem;
		font-weight: 600;
		margin: 30px 0;
		padding: 20px;
		border-radius: 5px;

		.socials_list {
			display: flex;

			a:not(:last-child) {
				margin-right: 20px;
			}
		}
	}
}

#page_routes {
	display: flex;
	justify-content: left;

	a {
		color: #fff;
		font-size: 1.6rem;
		text-decoration: underline;
		text-underline-offset: 3px;
		transition: color .3s;
		margin-right: 30px;

		&:hover {
			color: #39b54a;
		}
	}
}

#top_container {
	display: flex;
	padding: 0 40px;
}

#facility_carousel_wrapper {
	position: relative;
}

#facility_carousel {
	height: 400px;
	max-width: 670px;

	.caro_inner {
		margin: 0;

		.caro_img {
			border-radius: 10px;
		}
	}

	.dots_container {
		padding: 10px 0;
	}

	.caro_dot {
		background-color: transparent;
		border: 1px solid #fff;
		box-shadow: none;
		width: 12px;
		height: 12px;

		&.active {
			background-color: #fff;
			border: 1px solid #fff;
		}
	}

	.caro_left {
		border-radius: 10px 0 0 10px;
	}

	.caro_right {
		border-radius: 0 10px 10px 0;
	}

	.chevron {
		z-index: 1;
		background: rgba(darken(black, 10%), .4);
		border: none;

		&::before {
			color: #fff;
			right: 0;
		}
	}
}


@media screen and (max-width: 1440px) {

	#top_container {
		padding: 0;
	}

	#facility_info {
		padding-right: 40px;
		padding-top: 30px;
	}
}

@media screen and (max-width: 1075px) {

	#rates_top_overlay {
		height: 930px;
	}

	#top_container {
		flex-direction: column;
	}

	#facility_carousel {
		max-width: none;
	}

	#facility_info {

		/* stylelint-disable */
		* {
			padding: 5px 0;
		}

		.title {

			hgroup {
				margin-bottom: 0px;
			}
		}

		.phone {
			margin-bottom: 0px;
		}
	}

	#hr_social_container .socials {
		padding: 0 10px;

		h3 {
			padding-right: 10px;
		}
	}

	#facility_caro, #facility_info {
		width: 100%;
		margin: 0 auto;
	}

	#facility_caro {
		max-width: 650px;
	}
}

@media screen and (max-width: 620px) {

	#facility_carousel {
		height: 320px;
	}

	#rates_top_overlay {
		height: 870px;
	}
}

@media screen and (max-width: 520px) {

	#facility_info {
		padding: 0;

		.title {

			h2 {
				font-size: 2rem;
			}

			h3 {
				font-size: 1.2rem;
			}
		}

		.phone a {
			font-size: 1.2rem;
		}
	}

	#facility_carousel {
		height: 275px;

		.chevron {
			display: none;
		}
	}

	#rates_top_overlay {
		height: 730px;
	}

	#hr_social_container {
		padding: 0;

		.hours {

			.office_hours, .access_hours {
				margin-right: 35px;
				font-size: 1rem;

				h3 {
					font-size: 1.2rem;
				}
			}
		}

		.socials {
			margin: 15px 0;
			font-size: 1.3rem;
		}
	}

	#page_routes {
		flex-wrap: wrap;

		a {
			padding: 10px;
			font-size: 1.3rem;
			margin-right: 15px;
		}
	}
}