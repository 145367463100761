section {
	font-family: $font;
}

#rates_content_container {

	h3 {
		font-size: 1.5em;
		margin-bottom: .5em;
		color: $primary;
		padding: 0 10px;
	}
}

#hp_wrap {
	position: fixed;
	left: -9999px;
}

#coming_soon_form {
	padding: 0 10px;
	box-sizing: border-box;
	width: 100%;
	max-width: 800px;
	margin: 40px auto 0;

	label {
		display: none;
	}

	input, textarea {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		box-sizing: border-box;
		padding: 10px 0 10px 10px;
		color: $primary;
		border: 1px solid #fff;
		background: rgba(191, 148, 89, .25);
		font: 19px $font;

		&.error {
			border-color: #ef4136;
		}
	}

	textarea {
		height: 240px;
	}

	.contact_submit {
		width: 120px;
		font-size: 1.2rem;
		border: 2px solid #fff;
		box-shadow: 2px 2px 6px rgba(0, 0, 0, .39);
	}
}

#title_container {
	margin: 25px 0 50px 0;

	.internal_title {
		font-size: 2.3rem;
		padding: 10px 90px;
		display: inline-block;
		color: $primary;
		margin-right: -30px;
		position: relative;
		z-index: 1;
		cursor: pointer;

		&.active {
			z-index: 2;
			color: $primary;
			text-decoration: underline;
			text-underline-offset: 5px;
			text-decoration-color: $accent;
		}
	}

	.tab_content {
		display: none;

		/* stylelint-disable-next-line */
		&#rates_content_container {
			display: block;
		}

		img {
			max-width: 100%;
		}
	}
}


#rates_table {
	max-width: 1350px;
	margin: 0 auto;
	width: 100%;
}

#site_map {
	display: none;
	padding: 10px;

	img {
		width: 100%;
		max-width: 1330px;
	}
}

#rates-chart {
	width: 100%;
	margin: 0 auto;

	td {
		position: relative;
	}
}

#category {
	color: $primary;
	font-size: 19px;
	text-align: center;
	width: 100%;

	.unit-size {
		width: 15%;
		text-align: left;
		padding-left: 30px;
	}

	.unit-details {
		width: 22%;
	}

	.special-offer {
		width: 25%;
	}

	.monthly-rent {
		width: 15%;
	}

	.rate-button {
		width: 20%;
		padding: 15px 2%;
	}
}

.unit_row {
	width: 100%;
	transition: all .5s;
	color: $primary;

	&:nth-child(odd) {
		background: #f9f5ef;
	}

	&.unavailable {

		.special-offer {
			opacity: .67;
		}
	}

	td:not(:last-child):not(:nth-child(4)) {

		&::after {
			content: '';
			height: 70%;
			width: 2px;
			position: absolute;
			right: 0;
			top: 15%;
			background-color: $accent;
		}
	}

	.unit-size {
		font-size: 26px;
		font-weight: 700;
		vertical-align: middle;
		padding: 40px 30px;
		/* stylelint-disable-next-line */
		font-family: arial;

		.mobil_cat {
			display: none;
			text-align: center;
		}
	}

	.unit-details {
		font-size: 12px;
		vertical-align: middle;

		ul {
			display: block;
			width: 75%;
			margin: 0 auto;

			li {
				margin: 0;
				font-size: 17px;
				display: inline-flex;

				&:first-child {
					font-weight: 700;
					list-style: none;

					&:before {
						display: none;
					}

					&+li {
						margin-top: .25em;
					}
				}

				&:before {
					content: "";
					width: 4px;
					height: 4px;
					background: $primary;
					border-radius: 50%;
					float: left;
					margin: 6px 6px 0 0;
				}
			}
		}
	}

	.special-offer {
		font-size: 19px;
		font-weight: 700;
		text-align: center;
		vertical-align: middle;

		ul {
			font-size: 16px;
			font-weight: normal;
			padding-left: 2em;
			text-align: left;
			list-style-type: disc;
		}
	}

	.monthly-rent {
		text-align: center;
		vertical-align: middle;
		/* stylelint-disable-next-line */
		font-family: arial;

		.old {
			font-size: 25px;
			text-decoration: line-through;
			color: #8d8f92;
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 0;
		}

		.now {
			font-size: 26px;
			font-weight: 700;
		}

		.daily_rate {
			display: block;
			text-align: center;
			font-size: .7em;
			font-weight: normal;
			color: #8d8f92;
		}
	}

	.call_for_avail {
		text-decoration: none;
		color: $primary;
	}

	.rate-button {
		vertical-align: middle;
		text-align: center;
		padding-right: 10px;

		.btn_reserve {
			min-width: 100px;
			border: 2px solid #fff;
			text-align: center;
			box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, .25);

			&:hover {
				filter: saturate(1.3);
			}
		}

		.waitlist {
			color: #706462;
			min-width: 100px;
			background: transparent;
			border: 2px solid #ddd7d2;
			text-align: center;
			transition: color .3s ease-in-out, background .3s ease-in-out;

			&:hover {
				color: white;
				background: $secondary;

				&::after {
					border-right: 3px solid #fff;
					border-bottom: 3px solid #fff;
				}
			}

			&::after {
				border-right: 3px solid #f36d54;
				border-bottom: 3px solid #f36d54;
			}
		}
	}

	.call {
		color: $primary;
		font-weight: 400;
		font-size: 17px;
		text-decoration: none;
		opacity: .75;
	}
}

@media screen and (max-width: 800px) {

	.unit_row {

		.special-offer {
			font-size: 15px;
		}

		.monthly-rent {

			.old {
				font-size: 16px;
			}

			.new {
				font-size: 17px;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#rates-chart .unit-size {
		width: 10%;
	}

	.unit_row {

		.hide {
			display: none;
		}
	}
}

@media screen and (max-width: 650px) {

	#title_container .internal_title {
		padding: 10px 30px;
		font-size: 1.8rem;
	}

	#rates-chart td.unit-size {
		padding-left: 10px;
	}

	#category {
		font-size: 14px;
	}

	.unit_row {

		.unit-size {
			padding: 25px 10px;
			font-size: 20px;
		}

		.monthly-rent {

			.now {
				font-size: 20px;
			}
		}

		.unit-details {

			ul {
				width: 85%;

				li {
					font-size: 14px;
				}
			}
		}

		.rate-button {

			.btn_reserve, .waitlist {
				padding: 10px;
				min-width: auto;

				&::after {
					content: none;
				}
			}
		}
	}
}