#inc_reviews_wrapper {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	background: linear-gradient(0deg, $primary 0%, #063d1e 6%, $primary 100%);
	padding: 4em 0;
}

#inc_reviews_outer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 7em;
}

#inc_review_inner {
	display: flex;
	flex-direction: column;
	color: #fff;
	width: 450px;

	.review_title {
		font-family: $font;

		h2 {
			font-size: 2.2rem;
			font-weight: 500;
		}

		p {
			font-size: 1rem;
		}
	}
}

#inc_reviews_caro {

	.caro_inner {
		margin: 0;

		.caro_flex {
			display: flex;
			align-items: baseline;

			img {
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.review_top {
				font-weight: 600;
				margin-bottom: 10px;
			}
		}
	}
}

#inc_reviews_stars {
	display: flex;

	.stars {
		height: 20px;
		margin: 0 2px;
	}
}

@media screen and (max-width: 1000px) {

	.review_img {
		display: none;
	}
}

@media screen and (max-width: 500px) {

	#inc_reviews_outer {

		/* stylelint-disable-next-line */
		#inc_review_inner {
			width: 90%;
		}

		.caro_flex {
			flex-direction: column;
		}
	}
}