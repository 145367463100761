/**********************************
*>Reset
**********************************/
@import 'reset';
/**********************************
*>Global
**********************************/
@import 'global';
/**********************************
*>Header
**********************************/
@import 'header';
/**********************************
*>Footer
**********************************/
@import 'footer';
/**********************************
*>Index
**********************************/
@import 'index';
/**********************************
*>Internal
**********************************/
@import 'internal';
/***>>Facility***/
@import 'facility/facility_top';
@import 'facility/facility_rates';
@import 'facility';
/***>>Pay Online***/
@import 'pay-online';
/***>>FAQs/Tips***/
@import 'help';
/***>>Contact***/
@import 'contact';
/***>>Size Guide/Space Estimator***/
@import 'tools';
/***>>Error***/
@import 'error';
/***>>Reviews Caro***/
@import 'reviews_carousel';
/***>>Reservation System***/
@import 'reservation';
/***>>Nearby Facilities***/
@import 'nearby_map';
/***>>FAQs***/
@import 'faqs';
/***>>Features***/
@import 'features';