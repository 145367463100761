html {
	scroll-behavior: smooth;
}

#home {
	padding: 0;
	width: 100%;
	min-width: 320px;
	max-width: 1888px;
	background-image: none;

	/*stylelint-disable selector-max-id */

	#locations_wrapper {
		padding: 0 0 45px;
	}
	/*stylelint-enable selector-max-id */
}

#home_hero {
	display: flex;
	flex-direction: column;
	background-image: url('/dist/images/backgrounds/home_hero_new.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 13px;
	min-height: 736px;

	.home_hero_cta {
		font-family: $font;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		color: #fff;
		z-index: 1;

		.home_hero_banner {
			display: flex;
			align-items: center;
			background: $primary_light;
			font-family: $secondary_font;
			font-size: 32px;
			letter-spacing: 2px;
			padding: 16px 24px;
			margin: 35px 0;
			position: relative;

			img {
				margin: 0 5px;
			}

			span {
				margin: 0 30px;
			}

			&:before, &:after {
				content: "";
				position: absolute;
				background-image: url('/dist/images/icons/lower_banner_pc_ic.svg');
				background-repeat: no-repeat;
				bottom: -30px;
				left: -30px;
				width: 56px;
				height: 59px;
				z-index: -1;
			}

			&:after {
				left: auto;
				right: -30px;
				transform: scaleX(-1);
			}
		}

		h2 {

			font-size: 2.5rem;
			line-height: 1.4;
			padding: 0 35px;
			text-align: center;
			margin-bottom: 20px;

			span {
				font-family: $secondary_font;
				font-size: 3.5rem;
			}
		}

		.btn {

			padding: 15px 60px;
			font-size: 2rem;
			margin-bottom: 1em;

			&:after {
				display: none;
			}
		}
	}

	.home_hero_features {
		display: flex;
		background-color: rgba(0, 0, 0, .6);
		color: #fff;
		border-radius: 13px;

		.feature {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1 0 20%;
			padding: 50px 35px;
			box-sizing: border-box;
			position: relative;

			img {
				width: 58px;
				height: 50px;
				margin-bottom: 25px;
			}

			.title {
				font-size: 18px;
			}

			p {
				text-align: center;
			}

			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 0;
				width: 1px;
				transform: translateY(-50%);
				height: 40%;
				background: #4d4842;
			}
		}
	}
}

//Description Section with image on right

.mid-content {
	position: relative;

	.home-about-img {
		position: absolute;
		right: -65px;
		top: -105px;
		border-radius: 20px;
		max-width: 58%;
	}

	.mid-content-wrapper {
		width: 100%;
		background-color: #f9f4ee;
		padding: 60px 0px 1px 0px;
		margin-top: 100px;
		border-radius: 50px;
		position: relative;

		.mid-content-container {
			background-color: white;
			margin: 20px 20px 80px 20px;
			border-radius: 25px;
			position: relative;

			.mid-content-text {
				padding: 30px 100px;
				width: 31%;
				font-size: 1.2rem;
				line-height: 2;

				h1 {
					font-size: 2.2rem;
					color: $primary;
					font-weight: 700;
				}

				p {
					color: $primary;
					line-height: 2.7;
				}

				ul {

					li {
						list-style-type: disc;
						margin-left: 20px;
					}
				}
			}

			//Locations with map

			.locs {
				display: flex;
				margin: 27px 40px 20px 40px;
				padding: 5px 5px 90px 5px;

				.loc-map {
					width: 45%;
					height: 63vh;
					border: thin solid lightgray;
					border-radius: 15px;
				}

				.loc-right-container {
					display: flex;
					flex-direction: column;
					width: 55%;

					.loc-links-wrapper {
						display: grid;
						margin: 40px;
						align-items: center;
						grid-template-columns: 44% 36% 4% 5% 4%;
						grid-column-gap: 1em;

						h2 {
							font-size: 2.2rem;
							color: $primary;
							font-weight: 700;
						}

						h3 {
							font-size: 1.8rem;
							color: $primary;
						}

						a {
							padding-right: 30px;
						}
					}

					.loc-info {
						display: grid;
						margin: 9px 35px;
						align-items: center;
						/* justify-content: center; */
						grid-template-columns: 10% 41% 12% 61%;


						h1 {
							font-size: 4rem;
							color: $primary;
							font-weight: 700;
						}

						h2 {
							font-size: 2.2rem;
							color: $primary;
							font-weight: 700;
						}

						p {
							font-size: 1.3rem;
							color: $primary;
						}

						.rates-wrapper {
							display: flex;
							align-items: center;
						}
					}

					hr:not(:last-child) {
						width: 90%;
						border: solid 1px #f9f4ee;
					}

					hr:last-child {
						display: none;
					}

					.rates-divider {
						margin: 6px 0px 7px 34px;
						width: 93%;
						border: solid #f6f2e9 1px;
					}
				}
			}
		}
	}
}

/***>>Media Queries***/

@media screen and (max-width:1600px) {

	.mid-content {

		.home-about-img {
			position: relative;
			right: 0;
			top: 0;
			max-width: 650px;
			width: 100%;
			height: 425px;
			align-self: center;
		}

		.mid-content-wrapper {

			.mid-content-container {
				display: flex;
			}
		}
	}
}

@media screen and (max-width:1350px) {

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				padding-bottom: 60px;
				flex-direction: column;

				.mid-content-text {

					width: 85%;
				}
			}
		}
	}
}

@media screen and (max-width:1250px) {

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				.locs {
					flex-direction: column;
					padding: 0 5px 90px 5px;
					margin: 0 40px 20px 40px;

					.loc-map {
						width: 100%;
						height: 52vh;
					}

					.loc-right-container {

						hr:not(:last-child) {
							width: 163%;
							margin: 0px 68px;
						}

						.loc-links-wrapper {
							grid-template-columns: 99% 67% 6% 8% 6%;
						}

						.loc-info {
							margin: 1px 63px;
							align-items: center;
							grid-template-columns: 15% 93% 34% 112%;
						}
					}
				}

				.mid-content-text {
					width: 80%;
				}
			}
		}
	}
}

@media screen and (max-width:1100px) {

	#home_hero {

		.home_hero_cta {

			h2 {
				font-size: 2rem;
			}
		}

		.home_hero_features {

			flex-wrap: wrap;

			.feature {

				flex: 1 0 33.33%;
				padding: 25px;
			}
		}
	}

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				.locs {
					flex-direction: column;
					padding: 0 5px 90px 5px;
					margin: 0 40px 20px 40px;

					.loc-right-container {

						.loc-info {
							margin: 1px 63px;
							grid-template-columns: 21% 92% 27% 95%;
						}

						hr:not(:last-child) {
							width: 165%;
							margin: 0px 70px;
						}

						.loc-links-wrapper {
							grid-template-columns: 98% 70% 6% 8% 6%;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width:850px) {

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				.locs {

					.loc-right-container {

						hr:not(:last-child) {
							width: 169%;
							margin: 0px 62px;
						}

						.loc-links-wrapper {

							h2 {
								font-size: 1.8rem;
							}

							h3 {
								font-size: 1.5rem;
							}
						}

						.loc-info {
							grid-template-columns: 23% 88% 24% 96%;
							margin: 1px 49px;

							a {
								padding-right: 10px;
							}

							h2 {
								font-size: 1.8rem;
							}
						}
					}

					.loc-map {
						height: 40vh;
					}
				}

				.mid-content-text {
					margin: 0 auto;
					padding: 30px 94px;
				}
			}
		}
	}
}

@media screen and (max-width:750px) {

	#home_hero {

		min-height: 450px;
		background-position: center;

		.home_hero_cta {

			padding: 25px 0;

			.home_hero_banner {

				font-size: 24px;

				span {
					margin: 0 16px;
				}
			}

			h2 {
				line-height: 1.4;
				padding: 0 15px;

				span {
					font-size: 1.9rem;
				}
			}

			.btn {

				font-size: 1.5rem;
			}
		}

		.home_hero_features {

			.feature {

				flex: 1 0 50%;
				padding: 10px;

				img {
					width: 45px;
					height: 45px;
					margin-bottom: 10px;
				}

				.title {
					font-size: 14px;
				}
			}
		}
	}

	.mid-content {

		.mid-content-wrapper {
			margin-top: 25px;

			.mid-content-container {

				padding-top: 35px;
				padding-bottom: 35px;

				.mid-content-text {
					padding: 0 36px;
					width: 87%;

					h1 {
						font-size: 1.8rem;
					}
				}

				.locs {
					margin: 0;
					padding: 0 5px 6px 5px;

					.loc-right-container {
						width: 64%;

						hr:not(:last-child) {
							width: 138%;
							margin: 0px 32px;
						}

						.loc-links-wrapper {
							grid-template-columns: 131% 7% 11% 8%;

							h3 {
								display: none;
							}
						}

						.loc-info {
							margin: 1px 26px;
							grid-template-columns: 17% 75% 0% 100%;

							a {
								padding: 0;

								img {
									display: none;
								}
							}

							h1 {
								font-size: 2.6rem;
							}

							h2 {
								font-size: 1.6rem;
							}

							p {
								font-size: 1rem;
							}
						}
					}
				}
			}
		}

		.home-about-img {
			display: none;
		}
	}

	#home-carou {
		display: none;
	}

	.home-hero-locs-wrapper {
		flex-direction: column;
		align-items: center;
	}

	#home {

		.btn {

			&::after {
				top: 56%;
				height: 13px;
				width: 13px;
			}

			&.rates {
				width: 59%;

				a {
					padding: 4px;
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {

	#home_hero {

		.home_hero_cta {

			.home_hero_banner {

				justify-content: center;
				width: 100%;
				font-size: 18px;

				span {
					margin: 0 5px;
				}

				&:before, &:after {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width:500px) {

	.mid-content {

		.mid-content-wrapper {
			margin-top: 15px;
			padding: 31px 0 8px 0;

			.mid-content-container {
				margin: 23px 20px 20px 20px;

				.locs {

					.loc-right-container {

						margin: 0 auto;
						width: 100%;

						hr:not(:last-child) {
							width: 83%;
							margin: 12px 28px;
						}

						.loc-links-wrapper {
							display: flex;
							flex-direction: column;
							margin: 25px;

							a {
								display: none;
							}
						}

						.loc-info-wrapper {
							width: 100%;
							margin: 0 auto;
						}

						.loc-info {
							width: 100%;
							grid-template-columns: 9% 55%;
							justify-content: center;
							margin: 0 auto;
							text-align: center;

							.rates-wrapper {
								grid-column: 1/3;
								justify-content: center;
							}
						}
					}
				}

				.mid-content-text {
					width: 86%;
					line-height: 1.3;
					padding-top: 10px;

					p {
						line-height: 1.5;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 450px) {

	#home_hero {

		.home_hero_cta {

			h2 {
				margin-bottom: 10px;
				font-size: 22px;

				span {
					font-size: 24px;
				}
			}

			.btn {
				margin-bottom: 0;
			}
		}

		.home_hero_features {

			.feature {

				img {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}