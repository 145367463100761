#nav_container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#navbar_top {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	border-top: 10px solid #c0965a;
}

#nav {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	background: $primary;
	font-family: $font;

	a {
		text-decoration: none;
		color: #fff;
	}

	li {
		position: relative;
		padding: 1em;
		transition: background .3s;
		text-decoration: none;
		color: #fff;
		font-size: 20px;

		&:hover {
			background: lighten($primary, 10%);
		}

		&.active {
			background: $primary_light;
		}

		&.pay_bill_link {
			background: $primary_light;
			padding: 1em 2em;
			transition: background .3s;

			&:hover {
				background: lighten($primary, 10%);
			}
		}

		&.has_sub_menu {
			position: inherit;

			&:hover {

				.sub_menu {
					transform: scaleY(1);
				}
			}

			.sub_menu {
				position: absolute;
				top: calc(100% - 10px);
				left: 0;
				width: 100%;
				transform: scaleY(0);
				transform-origin: top;
				transition: transform .3s;
				z-index: 999;

				ul {
					display: flex;
					justify-content: center;
					align-items: center;
					background: lighten($primary, 10%);
				}

				li {
					position: relative;

					&::after {
						position: absolute;
						content: "";
						left: 50%;
						bottom: 15px;
						width: calc(100% - 30px);
						height: 1px;
						background: #fff;
						transform: translateX(-50%) scaleX(0);
						transition: transform .5s;
					}

					&:hover {
						background: none;

						&::after {
							transform: translateX(-50%) scaleX(1);
						}
					}
				}
			}
		}
	}
}

#mobile_nav {
	display: none;
}

#toggle_mobile_nav {
	display: block;
	position: absolute;
	height: 40px;
	width: 40px;
	padding: 0;
	margin: 0;
	background: $primary;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	border: none;

	&::before, &::after {
		height: 8px;
		width: 100%;
		background: #fff;
		margin: 8px 0;
		content: "";
		display: block;
		transition: transform .3s;
	}

	&[open] {

		&::before {
			transform: translateY(8px) rotate(45deg);
		}

		&::after {
			transform: translateY(-8px) rotate(-45deg);
		}
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

/***>>Media Queries***/
@media screen and (min-width: 751px) and (max-width: 800px) {

	#nav {

		li {
			font-size: 18px;
		}
	}
}

@media screen and (max-width: 750px) {

	#navbar_top {
		justify-content: flex-start;
		padding: 10px;
	}

	#nav {
		display: none;
	}

	#mobile_nav {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100vh;
		background: rgba($primary, .9);
		font-family: $font;
		transform: scaleY(0);
		transition: transform .5s;
		transform-origin: top;
		z-index: 999;

		li {
			width: 100%;
			padding: 10px 0;
			text-align: center;
		}

		a {
			color: #fff;
			font-size: 18px;
			font-weight: 700;
			text-decoration: none;

			&.pay_bill_link {
				background: $primary_light;
				padding: 5px 20px;
			}
		}

		&[open] {
			transform: scaleX(1);
		}
	}
}

@media screen and (max-width: 500px) {

	#header_logo {
		max-width: 250px;
	}
}